import React from "react";
import { Row, Col } from "antd";
import HomeSectionTwo from "../components/homesections/home-section-2/HomeSectionTwo";
import HomeSectionThree from "../components/homesections/home-section-3/HomeSectionThree";
import HomeSectionOne from "../components/homesections/home-section-one/HomeSectionOne";
import HomeSectionFour from "../components/homesections/home-section-4/HomeSectionFour";
import HomeSectionFive from "../components/homesections/home-section-5/HomeSectionFive";
import HomeSectionSix from "../components/homesections/home-section-6/HomeSectionSix";
import HomeSectionExtraOne from "../components/homesections/home-section-extra-1/HomeSectionExtraOne";
import Image1 from '../assets/imagesfireup/LOREAL.png';
import Image2 from '../assets/imagesfireup/COLGATE_PALMOLIVE.png';
import Image3 from '../assets/imagesfireup/COCACOLA.png';
import Image4 from '../assets/imagesfireup/P&G.png';
import Image5 from '../assets/imagesfireup/NATIONAL.png';
import Image6 from '../assets/imagesfireup/DALDA.png';

import Layout from "../layout/Layout";

const Home = () => {
  return (
    <Layout>
      <div style={{ width: "100%" }}>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <HomeSectionOne />
          </Col>
          <Col lg={24} md={24} sm={24}>
            <div className="logos-container">
              {/* <div>
                </div>
                <div>
                  </div>
                  <div>
                    </div>
                    <div>
                      </div> */}
            <marquee width="100%" direction="left" >

                <img
                  style={{ objectFit : "contain"}}
                  // src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f56ef34dba97a00a9e84b_L%27Ore%CC%81al_logo.svg"
                  src={Image1}
                />
                <img
                  style={{ objectFit : "contain"}}
                  // src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/62654f03732d385dfe29907a_Michelin_logo-light-blue.svg"
                  src={Image2}
                />
                <img
                  style={{ objectFit : "contain" }}
                  // src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f838ebcf3eb1415cc220f_Tatcha_logo.svg"
                  src={Image3}
                />
                <img
                  style={{ objectFit : "contain" }}
                  // src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f56f0fef3ec391ea7b61c_Shiseido_logo.svg"
                  src={Image4}
                />
              {/* <div>
                <img
                  style={{  objectFit : "contain" }}
                  // src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f56f0fef3ec93f8a7b61b_Tatcha_logo-2.svg"
                  src={Image5}
                />
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "100%", objectFit : "contain" }}
                  // src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f56f0fef3ec93f8a7b61b_Tatcha_logo-2.svg"
                  src={Image6}
                />
              </div> */}
              </marquee>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <HomeSectionTwo />
          </Col>
        </Row>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <HomeSectionThree />
          </Col>
        </Row>
        {/* <Row>
          <Col lg={24}>
            <HomeSectionExtraOne/>
          </Col>
        </Row> */}
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <HomeSectionFour />
          </Col>
        </Row>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <HomeSectionFive />
          </Col>
        </Row>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <HomeSectionSix />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Home;
