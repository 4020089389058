import { Layout } from "antd";
import React, { useState } from "react";
import Footer from "../components/footer/Footer";
import NavDrawer from "../components/navbar/navDrawer/NavDrawer";

const MobileLayout = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [childrenDrawer2, setChildrenDrawer2] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const showChildrenDrawer2 = () => {
    setChildrenDrawer2(true);
  };

  const onChildrenDrawerClose2 = () => {
    setChildrenDrawer2(false);
  };

  return (
    <div className="m-layout">
      <NavDrawer
        visible={visible}
        childrenDrawer={childrenDrawer}
        childrenDrawer2={childrenDrawer2}
        showDrawer={showDrawer}
        onClose={onClose}
        showChildrenDrawer={showChildrenDrawer}
        onChildrenDrawerClose={onChildrenDrawerClose}
        showChildrenDrawer2={showChildrenDrawer2}
        onChildrenDrawerClose2={onChildrenDrawerClose2}
      />
      {children}
      <Footer/>
    </div>
  );
};

export default MobileLayout;
