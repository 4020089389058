import { useState } from "react";
import { Form, Input, Button, Typography, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { Link, useHistory, Redirect } from "react-router-dom";
import swal from "sweetalert";

import { authLogin } from "../redux";
import FireLogoOne from "../assets/fireup-with-bg-remove.png";
import FireLogoBlack from "../assets/FireUp-black-removebg.png";

const SignIn = () => {
  //   const [loading, setLoading] = useState(false);
  //   const history = useHistory();
  //   const dispatch = useDispatch();

  //   const onFinish = async (values) => {
  // //     setLoading(true);
  // //     await dispatch(authLogin(values, history));
  //     setLoading(false);
  //   };

  //   if (localStorage.hasOwnProperty("token")) {
  //     return <Redirect to="/" />;
  //   } else {
  //     return (
  //       <>
  //         <div className="signin">
  //           <Form
  //             name="login"
  //             className="login-form"
  //             layout="vertical"
  //             onFinish={onFinish}
  //           >
  //             {/* <img src={logo} /> */}
  //             <h2>Employee Sign In</h2>
  //             <Form.Item
  //               name="email"
  //               rules={[
  //                 {
  //                   type: "email",
  //                   message: "The entered email is not valid!",
  //                 },
  //                 {
  //                   required: true,
  //                   message: "Email is Required",
  //                 },
  //               ]}
  //               label="Email"
  //             >
  //               <Input autoComplete="off" placeholder="Enter Email..." />
  //             </Form.Item>
  //             <Form.Item
  //               name="password"
  //               rules={[
  //                 {
  //                   required: true,
  //                   message: "Password is Required",
  //                 },
  //               ]}
  //               label="Password"
  //             >
  //               {/* <Input.Password */}
  //               <Input type="password" placeholder="Password..." />
  //             </Form.Item>

  //             <Form.Item>
  //               <Button loading={loading} type="primary" htmlType="submit">
  //                 Login
  //               </Button>
  //             </Form.Item>
  //           </Form>
  //         </div>
  //       </>
  //     );
  //   }
  return (
    <Row className="log-container">
      <Col xl={12} lg={12} md={12} sm={12}>
        <div className="log-left">
          <div className="logo-container">
            <img src={FireLogoBlack} />
          </div>
        </div>
      </Col>
      <Col xl={12} lg={12} md={12} sm={12}>
        <div className="log-right"></div>
      </Col>
    </Row>
  );
};

export default SignIn;
