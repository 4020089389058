import React, { useEffect } from "react";
import { Row, Col } from "antd";

// import ColaLogo from "../../../assets/Cola-bg.png";
import CPLogo from "../../../assets/cp-color-removebg-preview.png"
import ColaLogo from "../../../assets/cola-color-removebg-preview.png"
import pGLogo from "../../../assets/p_G-Logo-removebg-preview.png"
import nFLogo from "../../../assets/national-food-removebg-preview.png"
import LOLogo from "../../../assets/LOreal-Logo-1962-present-removebg-preview.png"
import daldaLogo from "../../../assets/dalda-removebg-preview.png"



// import "./HomeSectionOne.css";

const HomeSectionOne = () => {

  return (
    <Row className="section-one-container">
      <Col>
        {/* <div className="section-one-container"> */}
        {/* <div className="section-wrapper"> */}
        <Row>
          <Col xxl={12} xl={12} lg={12} md={16} sm={24}>
            <div className="section-one-content-container">
              <div className="section-one-content-wrapper">
                <h1
                  className="content-heading"
                  // style={{ color: "#ffcfbe" }}
                >
                  Your Platform for Performance-Driven Influencer Marketing
                </h1>
                <div class="content-text">
                  FireUp provides a complete software for managing all aspects
                  of influencer marketing programs and delivering exceptional
                  results.
                </div>
                <div className="button-container">
                  <a
                    href="/product"
                    class="button w-button"
                    style={{ color: "white" }}
                  >
                    Discover FireUp
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <div class="b-sidebar-popup b-sidebar-popup__v2">
          <div
            class="sidebar-popup sidebar-popup__v2"
            //   style="transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          >
            <div class="sidebar-popup-pt1">
              <div class="sidebar-popup__tab-name">Featured&nbsp;Content</div>
            </div>
            <a
              href="https://www.FireUp.com/influencer-marketing-impact-report"
              class="sidebar-popup-pt2 w-inline-block"
            >
              <div class="sidebar-popup-pt2__cover">
                <img src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/62baf3d3f5e0c97d2956bf95_Homepage%20slide-out%20banner.jpg" />
              </div>
              <div class="sidebar-popup-pt2__content">
                <h4 class="h4 pb-16 pt-8 pt-0-mob">
                  FireUp’s 2022 Influencer Marketing Impact Report
                  <br />
                </h4>
                <div
                  class="b-sidebar-popup-link"
                  // style="color: rgb(19, 19, 19);"
                >
                  <div>
                    <div class="inline">Read Now</div>
                    <div class="icon-font icon-font__btn-right"></div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div> */}
        <Row>
       
        </Row>
        {/* </div> */}
        {/* </div> */}
      </Col>
    </Row>
  );
};

export default HomeSectionOne;
