import React from "react";
import { Row, Col } from "antd";
// import './HomeSectionFour.css'

const HomeSectionFour = () => {
  return (
    <Row className="section-4-container">
      {/* <div className="section-4-container"> */}
      <Col lg={24}>
        <div className="section-4-wrapper">
          <Row className="div-block-174">
            <Col lg={13}>
              <div className="section-4-left-side">
                <h2 className="section-4-heading">
                  It’s hard to demonstrate the impact of influencer marketing.
                  But we know exactly how to do it.
                </h2>
                <div className="section-4-text">
                  Our customers are successful because our software and team of
                  experts are there every step of the way. With FireUp, you
                  will be able to measure your efforts, understand what’s
                  working, and make the right decisions.
                </div>
                <a href="/roi-calculator" className="section-4-button">
                  Calculate Your ROI with FireUp
                </a>
              </div>
            </Col>
            <Col lg={11}>
              <div className="section-4-img-container">
                <img
                  className="img-24"
                  // style={{ maxWidth: "470px" }}
                  src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f6b75585c2ad445eba7b6_home-s3-decor.jpg"
                />
              </div>
            </Col>
            {/* <img className="img-25" src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f6c384488a5ff7dae983b_home-s3-decor2.svg" /> */}
          </Row>
        </div>
      </Col>
      {/* </div> */}
    </Row>
  );
};

export default HomeSectionFour;
