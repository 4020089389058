import React from "react";
import { Row, Col } from "antd";
import Image from '../../../assets/engagement-rate-growth.png';
// import "./HomeSectionTwo.css";

const HomeSectionTwo = () => {
  return (
    <Row className="section-2-container">
      <Col lg={12} md={24} sm={24} xs={24}>
        <div className="section-2-img-container">
          <img src={Image} />
        </div>
      </Col>
      <Col lg={12} md={24} sm={24} xs={24}>
        <div className="section-2-content-text">
          <div>
            Sure, we do discovery, campaign management, influencer collaboration
            and reporting. But we also provide the largest global data set,
            one-of-a-kind market benchmarking, and a team of experts — to help
            you make smarter investments.
          </div>
        </div>
      </Col>
      
      <div>
        {/* <div className="section-2-wrapper"> */}
        {/* <div class="section-2-content-container"> */}
        {/* <div className="section-2-content-wrapper">
          <div className="content-text">
            Sure, we do discovery, campaign management, influencer collaboration
            and reporting. But we also provide the largest global data set,
            one-of-a-kind market benchmarking, and a team of experts — to help
            you make smarter investments.
          </div>
          <div className="img-container">
            <img src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f6519464998e133bad8ab_home-s2-decor.jpg" />
          </div>
        </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </Row>
  );
};

export default HomeSectionTwo;
