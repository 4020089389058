import React from "react";
import { Row, Col } from "antd";
// import "./HomeSectionFive.css";

const HomeSectionFive = () => {
  return (
    <Row className="section-5-container">
      <div className="section-5-wrapper">
        {/* <div className="anchor anchor__double-mobile"></div> */}
        <Col lg={24}>
          <div className="section-5-content-container">
            <Row>
              <Col lg={24} md={24} sm={24} xs={24}>
                <h2 className="section-5-content-heading">
                  Hear it from our customers
                </h2>
                <div className="section-5-content-text">
                  More than twenty of the top global advertisers and hundreds of
                  emerging brands and agencies depend on FireUp everyday to
                  make smarter influencer marketing decisions.
                </div>
              </Col>
            </Row>
            <Row gutter={[26, 0]} className="cards-column-container">
              <Col lg={8} md={12} sm={24} xs={24}>
                <div className="cards-col-1">
                  <div className="card-content">
                    <a
                      href="/resources/revlon-wonder-woman-1984-influencer-marketing-case-study"
                      className="card card__resources-home w-inline-block"
                    >
                      <div className="card-img-container">
                        <img src="https://assets-global.website-files.com/5e1409589314cc9e40aa2dae/601dc078936d946c7455e3ad_740x480.jpg" />
                      </div>
                      <div className="card-text-content">
                        <h4 className="card-title">
                          Revlon x Wonder Woman 1984
                        </h4>
                        <div className="card-text">
                          Learn how Revlon delivered extraordinary results for
                          its collection with the support of FireUp.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <div className="cards-col-2">
                  <div className="card-content">
                    <a
                      href="/resources/avene-influencer-marketing-case-study"
                      className="card card__resources-home w-inline-block"
                    >
                      <div className="card-img-container">
                        <img src="https://assets-global.website-files.com/5e1409589314cc9e40aa2dae/60f625cc6ebafcd92fb7deb9_Avene-image%20740.jpg" />
                      </div>
                      <div className="card-text-content">
                        <h4 className="card-title">Eau Thermale Avène</h4>
                        <div className="card-text">
                          How skincare brand, Eau Thermale Avène harnessed
                          traditional word-of-mouth marketing in a digital
                          context using an “always-on” influencer strategy.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <div className="cards-col-3">
                  <div className="card-content">
                    <a
                      href="/resources/traackr-glossy-state-of-influencer-marketing"
                      className="card card__resources-home w-inline-block"
                    >
                      <div className="card-img-container">
                        <img src="https://assets-global.website-files.com/5e1409589314cc9e40aa2dae/61578d738f87f612a286b914_blog%20post%20-%20%20740px%20x%20480px.jpg" />
                      </div>
                      <div className="card-text-content">
                        <h4 className="card-title">
                          FireUp x Glossy: State of Influencer Marketing{" "}
                        </h4>
                        <div className="card-text">
                          A deep-dive report about how top brands are building
                          more successful, measurable, and integrated influencer
                          marketing programs.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
              {/* <div className="w-dyn-list">
              <div role="list" className="swiper-container w-dyn-items"></div>
            </div> */}
            </Row>
          </div>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="section-5-button-container">
            <a
              href="/resources-tags/traackr-case-studies"
              className="section-5-button"
            >
              Explore More Case Studies
            </a>
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default HomeSectionFive;
