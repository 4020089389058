import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Switch } from "antd";

import { MdOutlineDarkMode, MdDarkMode } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

// import WhiteLogo from "../assets/Logo.png";
// import BlueLogo from "../assets/blueLogo.png";
import MainMenu from "./MainMenu";
import { toggleTheme } from "../redux";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

const VerticalLayout = ({ children, active }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { Header, Sider, Content } = Layout;
  const theme = useSelector((state) => state.theme.theme);

  return (
    <div className="v-layout">
      <Layout>
        <Navbar />
        <Content className="mainContent">{children}</Content>
        <Footer/>
      </Layout>
    </div>
  );
};

export default VerticalLayout;
