import React, { Children } from "react";
import "./Navdrop.css";

const Navdrop = ({ width, height, leftPosition , children}) => {
  console.log(leftPosition)
  return (
    <div
      style={{
        // display: "flex",
        flexDirection: "column",
        width: `${width}`,
        height: `${height}`,
        // display: `${display}`,
        position : 'absolute',
        left : leftPosition,
        
      }}
      className="drop-down-container"
    >{children}</div>
  );
};

export default Navdrop;
