import React, { useState, useEffect } from "react";
import { Select, Typography } from "antd";
import { RiArrowDownSFill } from "react-icons/ri";
import { HiDocumentText } from "react-icons/hi";
import { FaMicrophoneAlt, FaTicketAlt } from "react-icons/fa";
import { MdBook } from "react-icons/md";
import "./Navbar.css";
import FireLogoBlack from "../../assets/FireUp-black-removebg.png";
import Navdrop from "./navdropdown/Navdrop";

const { Text } = Typography;

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [fbLoaded, setFbLoaded] = useState(false);

  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: '968531898441981',
        cookie: true,
        xfbml: true,
        version: 'v10.0',
      });
      setFbLoaded(true);
      window.FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          setUser({
            name: response.name,
            email: response.email,
          });
        }
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  const handleLogin = () => {
    if (!fbLoaded) return; // Ensure FB SDK is loaded
    window.FB.login((response) => {
      if (response.authResponse) {
        window.FB.api('/me', { fields: 'name,email' }, (profile) => {
          setUser(profile);
        });
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    });
  };

  const handleLogout = () => {
    if (!fbLoaded) return; // Ensure FB SDK is loaded
    window.FB.logout(() => {
      setUser(null); 
      console.log("User logged out.");
    });
  };

  return (
    <div className="nav-container">
      <div className="nav-sec-1">
        <div className="logo">
          <img src={FireLogoBlack} alt="site-logo" />
        </div>
        <div className="menu">
          <ul>
            <li>Product</li>
            <li className="btn">
              <Navdrop width={"55rem"} height={"15rem"} leftPosition={-120}>
                <div className="product-hover-class">
                  <div className="drop-hover-class sec-1" style={{ borderRight: "0.005em solid #ef5a29" }}>
                    <Text className="drop-sec-title">TEAMS</Text>
                    <ul>
                      <li>For Brands</li>
                      <li>For Ecommerce</li>
                      <li>For CMOs</li>
                      <li>For Agencies</li>
                    </ul>
                  </div>
                  <div className="drop-hover-class sec-2">
                    <Text className="drop-sec-title">USE CASES</Text>
                    <div className="list-container">
                      <ul>
                        <li>Organic Campaigns</li>
                        <li>Paid Campaigns</li>
                        <li>Affiliate Programs and Social Commerce</li>
                        <li>Influencer Discovery and Vetting</li>
                        <li>Coordination and Visibility</li>
                      </ul>
                      <ul>
                        <li>Organic Campaigns</li>
                        <li>Paid Campaigns</li>
                        <li>Affiliate Programs and Social Commerce</li>
                        <li>Influencer Discovery and Vetting</li>
                        <li>Coordination and Visibility</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Navdrop>
              Solutions
              <RiArrowDownSFill />
            </li>
            <li>Customers</li>
            <li className="btn">
              <Navdrop width={"60rem"} height={"11rem"} leftPosition={-400}>
                <div className="learn-hover-class">
                  <div className="drop-hover-class" style={{ borderRight: "0.005em solid #ef5a29" }}>
                    <FaMicrophoneAlt className="drop-sec-icon" />
                    <Text className="drop-sec-title">Podcast</Text>
                    <Text className="drop-sec-text" style={{ fontSize: "13px" }}>
                      Listen to The Fast Traack to accelerate your influencer marketing knowledge.
                    </Text>
                  </div>
                  <div className="drop-hover-class" style={{ borderRight: "0.005em solid #ef5a29" }}>
                    <MdBook className="drop-sec-icon" />
                    <Text className="drop-sec-title">Resources</Text>
                    <Text className="drop-sec-text" style={{ fontSize: "13px" }}>
                      Case studies, insight reports and playbooks on successful influencer strategies.
                    </Text>
                  </div>
                  <div className="drop-hover-class" style={{ borderRight: "0.005em solid #ef5a29" }}>
                    <HiDocumentText className="drop-sec-icon" />
                    <Text className="drop-sec-title">Blog</Text>
                    <Text className="drop-sec-text" style={{ fontSize: "13px" }}>
                      Read the latest product news, trend analysis and editorial rifts on the blog.
                    </Text>
                  </div>
                  <div className="drop-hover-class">
                    <FaTicketAlt className="drop-sec-icon" />
                    <Text className="drop-sec-title">Events</Text>
                    <Text className="drop-sec-text" style={{ fontSize: "13px" }}>
                      Come join us for exclusive events with industry experts!
                    </Text>
                  </div>
                </div>
              </Navdrop>
              Learn
              <RiArrowDownSFill />
            </li>
          </ul>
        </div>
      </div>
      <div className="nav-sec-2">
        {user ? (
          <div>
            <span className="welcome-text">Welcome, {user.name}!</span>
            <button className="log-btn" onClick={handleLogout}>Logout</button>
          </div>
        ) : (
          <button className="log-btn" onClick={handleLogin} style={{
            backgroundColor: '#4267b2',
            color: '#fff',
            fontSize: '16px',
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}>
            Login with Facebook
          </button>
        )}
      </div>
    </div>
  );
};

export default Navbar;
