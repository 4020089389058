
import VerticalLayout from "./VerticalLayout";
import MobileLayout from './MobileLayout';
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

const Layout = ({ children, active }) => {
  return (
    <div className="layout">
      
      <VerticalLayout children={children} active={active} />
      <MobileLayout children={children}/>
      {/* {children} */}
      {/* <Footer/> */}
    </div>
  );
};

export default Layout;
