import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";

import Login from "../pages/Login";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const Routes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/privacypolicy" exact component={PrivacyPolicy} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
