import React from "react";
// import "./HomeSectionSix.css";

const HomeSectionSix = () => {
  return (
    <div class="section-6-container">
      <div class="section-6-wrapper">
        <h3 class="section-6-title">Ready to Get Started?</h3>
        <div class="section-6-button-container">
          <a href="/contact" class="section-6-button">
            Contact Sales
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionSix;
