import React from "react";
import { Row, Col } from "antd";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import FireLogo from "../../assets/FireUp-removebg.png";
import FireLogoBlack from "../../assets/FireUp-black-removebg.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./Footer.css";

const Footer = () => {
  const history = useHistory()
  return (
    <Row className="footer-container">
      <div className="footer-wrapper">
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="footer-header">
            <div className="footer-header-left">
              <div style={{ width: "100px", height: "26px" }}>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={FireLogoBlack}
                />
              </div>
              <div className="text-block-35">
                The System of Record for Data-Driven Influencer Marketing
              </div>
            </div>
            <div className="footer-header-right">
              <FaFacebook style={{ fontSize: "22px", color: "#456682" }} />
              <FaTwitter style={{ fontSize: "22px", color: "#456682" }} />
              <FaLinkedin style={{ fontSize: "22px", color: "#456682" }} />
              <FaInstagram style={{ fontSize: "22px", color: "#456682" }} />
            </div>
          </div>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="footer-content">
            <div className="footer-content-left">
              <div className="col-1">
                {/* <div className="col-1-content-1">
                  <div className="footer-left-content-title">Product</div>
                  <div className="footer-left-content-links">
                    <div id="link">Why FireUp</div>

                    <div id="link">For Brands</div>

                    <div id="link">For Ecommerce</div>

                    <div id="link">For CMOs</div>

                    <div id="link">For Agencies</div>
                  </div>
                </div> */}
                <div className="col-1-content-2">
                  <div className="footer-left-content-title">Company</div>
                  <div className="footer-left-content-links">
                    <div id="link">About</div>
                    <div id="link">Customers</div>

                    <div id="link">Careers</div>

                    <div id="link">Press</div>

                    <div id="link">Contact</div>
                    <div id="link" onClick={() => history.push('/privacypolicy')}>Privacy Policy</div>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="col-2-content-1">
                <div className="footer-left-content-title">Product</div>
                  <div className="footer-left-content-links">
                    <div id="link">Why FireUp</div>

                    <div id="link">For Brands</div>

                    <div id="link">For Ecommerce</div>

                    <div id="link">For CMOs</div>

                    <div id="link">For Agencies</div>
                  </div>
                </div>
                  {/* <div className="footer-left-content-title">Use cases</div>
                  <div className="footer-left-content-links">
                    <div id="link">Organic Campaigns</div>

                    <div id="link">Paid Campaigns</div>

                    <div id="link">
                      Affiliate Programs &amp; Social Commerce
                    </div>

                    <div id="link">Influencer Discovery &amp; Vetting</div>

                    <div id="link">Coordination &amp; Visibility</div>

                    <div id="link">Governance &amp; Compliance</div>

                    <div id="link">Investment Optimization</div>

                    <div id="link">Measurement Standardization</div>

                    <div id="link">Benchmarking</div>

                    <div id="link">Competitive Intelligence</div>
                  </div>
                </div> */}
                {/* <div className="col-2-content-2">
                  <div className="footer-left-content-title">Resources</div>
                  <div className="footer-left-content-links">
                    <div id="link">Case Studies</div>

                    <div id="link">Guides and How-Tos</div>

                    <div id="link">State of Influence Reports</div>

                    <div id="link">Newsletter</div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="footer-content-right">
              <div className="col-1">
                <div className="footer-right-content-title">
                  Don’t miss these
                </div>
                <div className="footer-right-col-1-content-wrapper">
                  <div
                    href="https://leaderboard.traackr.com/"
                    target="_blank"
                    className="content-sec-1"
                  >
                    <div className="content-title">
                      Beauty Brand Leaderboard
                    </div>
                    <div className="content-text">
                      In January 2022, about 17k influencers in the USmentioned
                      858 beauty brands. Collectively, they generated 596k VIT.
                    </div>
                    <div className="content-link">
                      Benchmark your brand{" "}
                      <BsArrowRight style={{ marginLeft: "5px" }} />
                    </div>
                  </div>
                  <div href="#" target="_blank" className="content-sec-2">
                    <div className="content-title">
                      Influencer Marketing Job Board
                    </div>
                    <div className="content-text">
                      Find top talent for your influencer marketing team with
                      FireUp’s Influencer Marketing Job Board.
                    </div>
                    <div className="content-link">
                      Post a job <BsArrowRight style={{ marginLeft: "5px" }} />
                    </div>
                  </div>
                  <div href="/roi-calculator" className="content-sec-3">
                    <div className="content-title">IM ROI Calculator</div>
                    <div className="content-text">
                      Calculate Your ROI with FireUp and improve the
                      performance of your paid influencer programs by between
                      15-70%.
                    </div>
                    <div className="content-link">
                      Calculate your ROI
                      <BsArrowRight style={{ marginLeft: "5px" }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="footer-right-content-title">
                  Latest from the blog
                </div>
                <div className="footer-right-card-container">
                  <div className="footer-right-card">
                    <div className="col-2-img-container">
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src="https://assets-global.website-files.com/5e1409589314cc9e40aa2dae/62e8310fc308928645542fb5_Traackr_InfluencerArticle_Image1_1920x680%20(1).jpg"
                      />
                    </div>
                    <div className="text-block-39">
                      4 Tips From hims &amp; hers On Building A Successful
                      Influencer Marketing Program
                    </div>
                  </div>
                  <div className="footer-right-card">
                    <div className="col-2-img-container">
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src="https://assets-global.website-files.com/5e1409589314cc9e40aa2dae/62e05b49e1c732df115770e5_Job%20Board_%201920%20X%20687.png"
                      />
                    </div>
                    <div className="text-block-39">
                      FireUp Launches New Influencer Marketing Job Board to
                      Help Brands Scale{" "}
                    </div>
                  </div>
                  <div className="footer-right-card">
                    <div className="col-2-img-container">
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src="https://assets-global.website-files.com/5e1409589314cc9e40aa2dae/62dee3eab77b86d9db8f5e6f_IM%20Resume_%201920%20X%20685.png"
                      />
                    </div>
                    <div className="text-block-39">
                      How to Write a Standout Resume for Influencer Marketing
                      Jobs
                    </div>
                  </div>
                </div>
                <div className="text-block-38">
                  See More <BsArrowRight style={{ marginLeft: "5px" }} />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default Footer;
