import React from "react";
import { Carousel, Row, Col } from "antd";
import picOne from "../../../assets/carousel-1-pic-1.jpg";
import picTwo from "../../../assets/carousel-1-pic-2.jpg";
import picThree from "../../../assets/carousel-1-pic-3.jpg";
// import "./HomeSectionThree.css";

const HomeSectionThree = () => {
  // const contentStyle = {
  //   height: "100%",
  //   width: "100%",
  //   color: "#fff",
  //   lineHeight: "100%",
  //   textAlign: "center",
  //   background: "transparent",
  // };

  // const contentStyle = {
  //   height: "100%",
  //   width: "444px",
  //   color: "#fff",
  //   borderRadius: "5px",
  //   // lineHeight: "100%",
  //   textAlign: "center",
  //   background: "#364d79",
  // };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  return (
    <Row className="section-3-container">
      <Col xl={24} lg={24} md={24} sm={24}>
        {/* <div > */}
        {/* <div className="section-3-wrapper"> */}
        <Row className="big-title-section">
          {/* <div> */}
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h2 className="big-title-section-heading">
              Be data-obsessed &amp; optimize initiatives like the team at
              Shiseido
            </h2>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row className="big-title-section-block-container reverse">
              <Col xl={10} lg={12} md={16} sm={24} xs={24}>
                <div className="div-block-180">
                  <div>
                    <div className="div-block-182">
                      <div className="div-block-175">
                        <img src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/6138e1f279a69e8dd36d2b67_Erin%20Kelly.jpg" />
                        <div className="quote">
                          FireUp is our partner of choice because their tools
                          give us the capability to take a more strategic
                          approach when developing our influencer programs.
                        </div>
                      </div>
                      <div className="div-block-176">
                        <div className="quote__name">Erin Kelly</div>
                        <div className="quote__text">
                          Director, Integrated Comm. &amp; Influencer Engagement
                          <br />
                          Shiseido
                        </div>
                      </div>
                    </div>
                    <div className="div-block-178">
                      <div
                        href="/use-cases/market-benchmarking"
                        className="link-block-5"
                      >
                        <div className="text-block-22">
                          Shiseido uses FireUp's insights to analyze data on a
                          holistic program level to shape and optimize strategy.
                        </div>
                        <div className="div-block-179">
                          <div className="text-block-21">
                            Learn about Benchmarking in FireUp
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={14} lg={12} md={24} sm={24} xs={24}>
                <div className="div-block-181">
                  <Carousel autoplay effect="fade">
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        // src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f77894aee3aa7fd17dd8d_benchmarking-s1.jpg"
                        src={picOne}
                      />
                    </div>
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        // src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f778ac2722c5eae67fae6_benchmarking-s2.jpg"
                        src={picTwo}
                      />
                    </div>
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        // src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f778d585c2a2f4bebe455_benchmarking-s3.jpg"
                        src={picThree}
                      />
                    </div>
                  </Carousel>
                </div>
              </Col>
            </Row>
          </Col>
          {/* </div> */}
        </Row>
        <Row className="big-title-section">
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h2 className="big-title-section-heading">
              Build awareness &amp; drive sales with smart partnerships like the
              team at Beekman 1802
            </h2>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row className="big-title-section-block-container">
              <Col xl={14} lg={12} md={24} sm={24} xs={24}>
                <div className="div-block-181-inverted">
                  <Carousel autoplay effect="fade">
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f8137f36f21084737ec99_campaign-management-s1.jpg"
                      />
                    </div>
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f8139fc48d90b6e11672f_campaign-management-s2.jpg"
                      />
                    </div>
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f813be4a7af70d3e75504_campaign-management-s3.jpg"
                      />
                    </div>
                  </Carousel>
                </div>
              </Col>
              <Col xl={10} lg={12} md={16} sm={24} xs={24}>
                <div className="div-block-inverted">
                  <div className="div-block-182">
                    <div className="div-block-175">
                      <img src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f8139fc48d90b6e11672f_campaign-management-s2.jpg" />
                      <div className="quote">
                        FireUp shows us who aligns and performs best for us and
                        our competitors so we can invest our resources
                        confidently.
                      </div>
                    </div>
                    <div className="div-block-176">
                      <div className="quote__name">Brad Farrell</div>
                      <div className="quote__text">
                        CMO
                        <br />
                        Beekman 1802
                      </div>
                    </div>
                  </div>
                  <div className="div-block-178">
                    <div
                      href="/use-cases/campaign-management"
                      className="link-block-5"
                    >
                      <div className="text-block-22">
                        Beekman 1802 used FireUp to sell out two product lines
                        at Ulta and become the number one skincare brand on
                        TikTok.
                      </div>
                      <div className="div-block-179">
                        <div className="text-block-21">
                          Learn about Campaign Management in FireUp
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="big-title-section">
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h2 className="big-title-section-heading">
              Gain visibility &amp; align with agency partners like the team at
              Colgate-Palmolive
            </h2>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row className="big-title-section-block-container reverse">
              <Col xl={10} lg={12} md={16} sm={24} xs={24}>
                <div className="div-block-180">
                  {/* <div> */}
                  <div className="div-block-182">
                    <div className="div-block-175">
                      <img src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/61309fa8ac1ed720f9013448_Barbara%20Mugica.jpg" />
                      <div className="quote">
                        FireUp’s platform, insights and expertise helped us
                        generate greater impact per Euro spent by increasing
                        transparency and enabling coordination.
                      </div>
                    </div>
                    <div className="div-block-176">
                      <div className="quote__name">Barbara Mugica</div>
                      <div className="quote__text">
                        Associate Director for Personal Care Digital Marketing
                        <br />
                        Colgate-Palmolive Europe
                      </div>
                    </div>
                  </div>
                  <div className="div-block-178">
                    <div
                      href="/use-cases/program-management"
                      className="link-block-5"
                    >
                      <div className="text-block-22">
                        Colgate-Palmolive reduced its influencer campaign costs
                        by 40% using FireUp to align with their agency partners
                        in one system of record.
                      </div>
                      <div className="div-block-179">
                        <div className="text-block-21">
                          Learn more about Program Management in FireUp
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </Col>
              <Col xl={14} lg={12} md={24} sm={24} xs={24}>
                <div className="div-block-181">
                  <Carousel autoplay effect="fade">
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f8030cc574671450663a2_program-management-s1.jpg"
                      />
                    </div>
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f80322a72bf6d54fad580_program-management-s2.jpg"
                      />
                    </div>
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f8035077646d8e6161bde_program-management-s3.jpg"
                      />
                    </div>
                  </Carousel>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="big-title-section">
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <h2 className="big-title-section-heading">
              Choose impactful influencers &amp; prove ROI&nbsp;like the team at
              Media Bounty
            </h2>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row className="big-title-section-block-container">
              <Col xl={14} lg={12} md={24} sm={24} xs={24}>
                <div className="div-block-181-inverted">
                  <Carousel autoplay effect="fade">
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f82bc5f2468a4a03d69fd_for-agencies-s1.jpg"
                      />
                    </div>
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f82be4695632a4554c28e_for-agencies-s2.jpg"
                      />
                    </div>
                    <div className="carousel-class-img">
                      <img
                        // style={contentStyle}
                        src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/612f82c07b675187770d7b27_for-agencies-s3.jpg"
                      />
                    </div>
                  </Carousel>
                </div>
              </Col>
              <Col xl={10} lg={12} md={16} sm={24} xs={24}>
                <div className="div-block-180">
                  <div className="div-block-182">
                    <div className="div-block-175">
                      <img src="https://assets-global.website-files.com/5e1409589314cc7fecaa2d8e/6138e2d12b12902bcd1344cd_Aisling.jpg" />
                      <div className="quote">
                        With FireUp, we know that the influencers we select will
                        drive the most impact for our clients and we can prove
                        it to the clients as well. The data and insights they
                        provide help us deliver top performing campaigns time
                        and time again.
                      </div>
                    </div>
                    <div className="div-block-176">
                      <div className="quote__name">Ash McGuinness</div>
                      <div className="quote__text">
                        Account &amp; Community Manager
                        <br />
                        Media Bounty
                      </div>
                    </div>
                  </div>
                  <div className="div-block-178">
                    <div href="/agencies" className="link-block-5">
                      <div className="text-block-22">
                        Media Bounty increased client trust and retention after
                        improving influencer selection using FireUp’s discovery,
                        vetting, and brand safety tools.
                      </div>
                      <div className="div-block-179">
                        <div className="text-block-21">
                          Learn more about FireUp for agencies
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* </div> */}
        {/* </div> */}
      </Col>
    </Row>
  );
};

export default HomeSectionThree;
